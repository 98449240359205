import * as React from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ClassName } from 'src/lib';

import type { UserModel } from 'src/app';

import * as ROUTES from 'src/router/routes';

import { generatePath } from 'src/utils';

import './intl';

export interface IHeaderProfilePropTypes {
	user: UserModel;
}

const cn = ClassName.create('header-profile', require('./style.less'));

export function HeaderProfileComponent(props: IHeaderProfilePropTypes): React.ReactElement<IHeaderProfilePropTypes> {
	const intl = useIntl();

	const { user } = props;
	const { isAuthorized } = user;

	const leaderboard = user.app.models.leaderboard;

	const params = useParams<{
		organizationId?: string;
		resourceId: string;
	}>();

	return (
		<Link
			className={cn.get()}
			to={generatePath(ROUTES.PROFILE_ACTIVE_SEASON, {
				organizationId: params.organizationId ?? leaderboard.currentLeaderboardId.organizationId,
				resourceId: params.resourceId ?? leaderboard.currentLeaderboardId.resourceId,
			})}
		>
			{isAuthorized && (
				<span
					className={cn.get('avatar')}
					style={{
						backgroundImage: !!user.profileImageUrl ? `url(${user.profileImageUrl})` : '',
					}}
				/>
			)}
			<span className={cn.get('label')}>
				{isAuthorized ? user.username : intl.formatMessage({ id: 'header.profile.unauthtorized' })}
			</span>
		</Link>
	);
}

export const HeaderProfile = observer(HeaderProfileComponent);
