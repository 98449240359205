import * as React from 'react';

import { ClassName } from 'src/lib';

export interface IMarqueePropTypes extends React.PropsWithChildren {
	className?: string;
	children?: React.ReactNode;
	duration?: number;
	delayIn?: number;
	delayOut?: number;
	isScrolling?: boolean;
	isScrollingOnHover?: boolean;
}

interface IMarqueeStateTypes {
	position: number;
	isScrolling: boolean;
	isResizing: boolean;
}

const cn = ClassName.create('marquee', require('./style.less'));

export class Marquee extends React.PureComponent<IMarqueePropTypes, IMarqueeStateTypes> {
	public static defaultProps = {
		duration: 7000,
		delayIn: 300,
		delayOut: 1000,
		isScrolling: false,
		isScrollingOnHover: true,
	};

	protected $resizeObserver: ResizeObserver | null = null;

	protected $ref: { root: React.RefObject<HTMLDivElement>; content: React.RefObject<HTMLDivElement> } = {
		root: React.createRef(),
		content: React.createRef(),
	};

	private _resizeTimeoutId: number = 0;

	private _animationTimeoutId: number = 0;

	public override state: IMarqueeStateTypes = {
		position: 0,
		isScrolling: this.props.isScrolling || false,
		isResizing: false,
	};

	public override componentDidMount(): void {
		this.$resizeObserver = new ResizeObserver(this._onResize);

		this.$resizeObserver.observe(this.$ref.root.current as HTMLDivElement, { box: 'border-box' });
		this.$resizeObserver.observe(this.$ref.content.current as HTMLDivElement, { box: 'border-box' });

		this._scroll(false, !!this.props.isScrolling);
	}

	public override componentDidUpdate(
		prevProps: Readonly<IMarqueePropTypes>,
		prevState: Readonly<IMarqueeStateTypes>
	): void {
		this.setState(
			{
				isScrolling:
					prevProps.isScrolling !== this.props.isScrolling
						? !!this.props.isScrolling
						: this.state.isScrolling,
			},
			() => {
				this._scroll(prevState.isScrolling, this.state.isScrolling);
			}
		);
	}

	public override componentWillUnmount(): void {
		window.clearTimeout(this._resizeTimeoutId);
		window.clearTimeout(this._animationTimeoutId);

		this.$resizeObserver?.unobserve(this.$ref.root.current as HTMLDivElement);
		this.$resizeObserver = null;
	}

	private _scroll(prevIsScrolling: boolean, currIsScrolling: boolean): void {
		if (currIsScrolling === prevIsScrolling) {
			return;
		}

		if (!prevIsScrolling && currIsScrolling) {
			window.clearTimeout(this._animationTimeoutId);

			this.setState(
				{
					isScrolling: true,
				},
				() => {
					this._animationTimeoutId = window.setTimeout(this._animatePosition, this.props.delayIn);
				}
			);
		}

		if (prevIsScrolling && !currIsScrolling) {
			this.setState(
				{
					position: 0,
				},
				() => {
					window.clearTimeout(this._animationTimeoutId);
				}
			);
		}
	}

	private _animatePosition = () => {
		window.clearTimeout(this._animationTimeoutId);

		if (!this.state.isScrolling) {
			return;
		}

		let nextPosition = 0;
		let delay = this.props.delayOut;

		if (this.state.position === 0) {
			delay = this.props.delayIn;
			nextPosition =
				(this.$ref.content.current as HTMLDivElement)?.offsetWidth -
				(this.$ref.root.current as HTMLDivElement)?.offsetWidth;

			if (nextPosition < 0) {
				nextPosition = 0;
			}
		}

		this.setState(
			{
				position: nextPosition,
			},
			() => {
				this._animationTimeoutId = window.setTimeout(
					this._animatePosition,
					(this.props.duration ?? 0) + (delay ?? 0)
				);
			}
		);
	};

	private _onResize = () => {
		window.clearTimeout(this._resizeTimeoutId);
		window.clearTimeout(this._animationTimeoutId);

		this.setState(
			{
				position: 0,
				isResizing: true,
			},
			() => {
				this._resizeTimeoutId = window.setTimeout(() => {
					this.setState(
						{
							isResizing: false,
						},
						() => {
							this._animationTimeoutId = window.setTimeout(this._animatePosition, this.props.delayIn);
						}
					);
				}, 150);
			}
		);
	};

	private _onMouseEnter = (event: React.MouseEvent) => {
		event.preventDefault();

		if (!this.props.isScrollingOnHover) {
			return;
		}

		this.setState({
			isScrolling: true,
		});
	};

	private _onMouseLeave = (event: React.MouseEvent) => {
		event.preventDefault();

		if (!this.props.isScrollingOnHover) {
			return;
		}

		this.setState({
			isScrolling: false,
		});
	};

	private _renderContent(): React.ReactNode {
		return (
			<div
				ref={this.$ref.content}
				className={cn.get('content')}
				style={{
					transform: `translateX(-${this.state.position}px)`,
					transitionDuration: `${
						this.state.isScrolling && !this.state.isResizing ? this.props.duration : 1000
					}ms`,
				}}
			>
				{this.props.children}
			</div>
		);
	}

	public override render(): React.ReactNode {
		return (
			<div
				ref={this.$ref.root}
				className={ClassName.join(
					cn.get([this.props.isScrolling || (this.state.isScrolling && 'scrolling')]),
					this.props.className
				)}
				onMouseEnter={this._onMouseEnter}
				onMouseLeave={this._onMouseLeave}
			>
				{this._renderContent()}
			</div>
		);
	}
}
