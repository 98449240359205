import * as React from 'react';

import { BiPlay, BiPause } from 'react-icons/bi';

import { ClassName } from 'src/lib';

const cn = ClassName.create('video-player', require('./style.less'));

export interface IVideoPlayerPropTypes {
	className?: React.ReactHTMLElement<HTMLVideoElement>['props']['className'];
	src: string;
}

export function VideoPlayer(props: IVideoPlayerPropTypes): React.ReactNode {
	const [isPlaying, setIsPlaying] = React.useState(false);
	const refVideo: React.RefObject<HTMLVideoElement> = React.useRef(null);

	return (
		<div className={ClassName.join(cn.get([isPlaying && 'playing']), props.className)}>
			<div className={cn.get('overlay')}>
				<button
					className={cn.get('overlay', 'control')}
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();

						if (refVideo.current instanceof HTMLVideoElement) {
							if (isPlaying) {
								refVideo.current?.pause();
								return;
							}

							refVideo.current?.play();
						}
					}}
				>
					<span className={cn.get('overlay', 'control', 'icon', ['play'])}>
						<BiPlay />
					</span>
					<span className={cn.get('overlay', 'control', 'icon', ['pause'])}>
						<BiPause />
					</span>
				</button>
				<div className={cn.get('overlay', 'background')} />
			</div>
			<video
				className={cn.get('player')}
				ref={refVideo}
				src={props.src}
				autoPlay={false}
				onPlay={() => {
					setIsPlaying(true);
				}}
				onPause={() => {
					setIsPlaying(false);
				}}
				onEnded={() => {
					setIsPlaying(false);
				}}
			/>
		</div>
	);
}
