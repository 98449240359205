// extracted by mini-css-extract-plugin
var _1 = "bpl__gift__Wm5HV";
var _2 = "bpl__gift__amount__V_B5i";
var _3 = "bpl__gift__hint__VgQfn";
var _4 = "bpl__gift__hint__label__u86Mr";
var _5 = "bpl__gift__hint__label__m-dismissible__KUB4s";
var _6 = "bpl__gift__hint__label__m-original___c34R";
var _7 = "bpl__gift__hint__promocode__pHRzp";
var _8 = "bpl__gift__media__CQ3GV";
var _9 = "bpl__gift__media__item__jvLU4";
var _a = "bpl__gift__media__item__m-stub__lR1BC";
var _b = "bpl__gift__title__P0u4Y";
var _c = "bpl__gift__title__marquee__m12ld";
var _d = "bpl__gift__title__marquee__m-dismissible__X451c";
var _e = "bpl__gift__title__promocode__yxAbx";
export { _1 as "gift", _2 as "gift__amount", _3 as "gift__hint", _4 as "gift__hint__label", _5 as "gift__hint__label__m-dismissible", _6 as "gift__hint__label__m-original", _7 as "gift__hint__promocode", _8 as "gift__media", _9 as "gift__media__item", _a as "gift__media__item__m-stub", _b as "gift__title", _c as "gift__title__marquee", _d as "gift__title__marquee__m-dismissible", _e as "gift__title__promocode" }
