import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';

import _noop from 'lodash/noop';

import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';

import { ClassName } from 'src/lib';
import type { LeaderboardModel, LeaderboardsGiftsModel } from 'src/app';

import { useBreakpoint } from 'src/hooks';

import { LeaderboardRow } from './row';
import { LeaderboardPagination } from './pagination';

import './intl';

export interface ILeaderboardPropTypes {
	leaderboard: LeaderboardModel;
	setPage: (page: number) => void;
}

const cn = ClassName.create('leaderboard', require('./style.less'));

function getGiftForLeaderboardParticipant(
	giiftsList: LeaderboardsGiftsModel['list'],
	leaderboardItem?: LeaderboardModel['participants'][0]
): (LeaderboardsGiftsModel['list'][0] & { title?: string }) | undefined {
	if (!leaderboardItem) {
		return;
	}

	return giiftsList.find((gift) => {
		const position = leaderboardItem?.position ?? 0;

		return position >= gift.position[0] && position <= (gift.position[1] ?? gift.position[0]);
	});
}

export function LeaderboardComponent(props: ILeaderboardPropTypes): React.ReactElement<ILeaderboardPropTypes> {
	const { leaderboard } = props;

	const intl = useIntl();

	const refList = React.useRef<List | null>(null);
	const refScrollbarWidth = React.useRef<number>(0);

	const { isMobile } = useBreakpoint();

	React.useLayoutEffect(() => {
		const temp = document.createElement('div');
		temp.style.cssText =
			'overflow: scroll; visibility: hidden; position: absolute; inset: 0; pointer-events: none;';
		document.body.appendChild(temp);
		refScrollbarWidth.current = temp.offsetWidth - temp.clientWidth;
		document.body.removeChild(temp);
	}, []);

	const page = leaderboard.participantsPagination.current;
	const [isScrolled, setIsScrolled] = React.useState(false);

	const setPage = React.useCallback(
		(page: number) => {
			props.setPage(page);
			refList.current?.scrollToPosition(0);
			setIsScrolled(true);
		},
		[leaderboard.participantsPagination.current]
	);

	const list = React.useMemo(() => {
		const indexStart = (page - 1) * leaderboard.participantsPagination.size;

		return leaderboard.participants.slice(indexStart, indexStart + leaderboard.participantsPagination.size);
	}, [leaderboard.participants, leaderboard.participantsPagination]);

	const rowHeight = {
		desktop: 44,
		mobile: 102,
	};

	const getRowHeight = (): number => {
		return isMobile ? rowHeight.mobile : rowHeight.desktop;
	};

	React.useEffect(() => {
		const index = list.findIndex((item) => item.isRequestedUser);

		if (index < 0) {
			return;
		}

		refList.current?.scrollToPosition(getRowHeight() * Math.max(index - 5, 0));
	}, [list, isScrolled]);

	const isLoading = leaderboard.isLoading;

	return (
		<div className={cn.get()}>
			<div className={cn.get('header')}>
				<div className={cn.get('header', 'position')}>{intl.formatMessage({ id: 'leaderboard.position' })}</div>
				<div className={cn.get('header', 'username')}>{intl.formatMessage({ id: 'leaderboard.username' })}</div>
				<div className={cn.get('header', 'gift')}>{intl.formatMessage({ id: 'leaderboard.gift' })}</div>
				<div className={cn.get('header', 'score')}>{intl.formatMessage({ id: 'leaderboard.score' })}</div>
			</div>
			{!!leaderboard.requestedUserParticipant && (
				<div className={cn.get('current-user')}>
					<LeaderboardRow
						username={leaderboard.requestedUserParticipant?.username}
						position={leaderboard.requestedUserParticipant?.position}
						score={leaderboard.requestedUserParticipant?.score}
						gift={
							getGiftForLeaderboardParticipant(
								leaderboard.currentLeaderboardGifts.list,
								leaderboard.requestedUserParticipant
							)?.title
						}
						isSelected
					/>
				</div>
			)}
			<div
				className={cn.get('list')}
				style={{
					minHeight: getRowHeight() * 11,
				}}
			>
				<AutoSizer>
					{({ width, height }) => (
						<List
							ref={(element) => {
								refList.current = element;
							}}
							width={width + refScrollbarWidth.current}
							height={height}
							rowHeight={getRowHeight()}
							rowCount={list.length}
							rowRenderer={({ key, index, style }) => {
								const row = list[index];

								return (
									<div key={key} style={style}>
										<LeaderboardRow
											username={row?.username ?? ''}
											position={row?.position ?? 0}
											score={row?.score ?? 0}
											gift={
												getGiftForLeaderboardParticipant(
													leaderboard.currentLeaderboardGifts.list,
													row
												)?.title ?? ''
											}
											isSelected={!!row?.isRequestedUser}
											isLoading={isLoading}
										/>
									</div>
								);
							}}
						/>
					)}
				</AutoSizer>
			</div>
			<div className={cn.get('pagination')}>
				<LeaderboardPagination
					page={props.leaderboard.participantsPagination.current}
					pageSize={props.leaderboard.participantsPagination.size}
					totalSize={props.leaderboard.participantsSize}
					setPage={setPage}
				/>
			</div>
		</div>
	);
}

export const Leaderboard = observer(LeaderboardComponent);
