import * as React from 'react';

import { ClassName } from 'src/lib';

export interface IActionTableRowPropTypes extends React.PropsWithChildren {
	status?: React.ReactNode;
	action?: React.ReactNode;
}

const cn = ClassName.create('action-table-row', require('./style.less'));

export function ActionTableRow(props: IActionTableRowPropTypes): React.ReactElement<IActionTableRowPropTypes> {
	return (
		<div className={cn.get()}>
			<div className={cn.get('status')}>{props.status}</div>
			<div className={cn.get('content')}>{props.children}</div>
			<div className={cn.get('action')}>{props.action}</div>
		</div>
	);
}
