import * as React from 'react';

import { ClassName } from 'src/lib';

const cn = ClassName.create('page-header', require('./style.less'));

export interface IPageHeaderPropTypes extends React.PropsWithChildren {
	className?: React.ReactElement<HTMLDivElement>['props']['className'];
}

export function PageHeader(props: IPageHeaderPropTypes): React.ReactNode {
	return <div className={ClassName.join(cn.get(), props.className)}>{props.children}</div>;
}
