import * as React from 'react';

import { ClassName } from 'src/lib';

const cn = ClassName.create('page', require('./style.less'));

export interface IPagePropTypes extends React.PropsWithChildren {
	className?: React.ReactElement<HTMLDivElement>['props']['className'];
}

export function Page(props: IPagePropTypes): React.ReactNode {
	return <div className={ClassName.join(cn.get(), props.className)}>{props.children}</div>;
}

export * from './head';
export * from './body';
export * from './header';
