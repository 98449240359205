// extracted by mini-css-extract-plugin
var _1 = "bpl__gifts-carousel-gifts__H1ubU";
var _2 = "bpl__gifts-carousel-gifts__dots__K1xXh";
var _3 = "bpl__gifts-carousel-gifts__dots__item__Lzi9U";
var _4 = "bpl__gifts-carousel-gifts__dots__item__m-active__OMpra";
var _5 = "bpl__gifts-carousel-gifts__media__TgGEf";
var _6 = "bpl__gifts-carousel-gifts__media__item__vJx0k";
var _7 = "bpl__gifts-carousel-gifts__title__Byv5x";
var _8 = "bpl__gifts-carousel-gifts__title__value__WXHQL";
export { _1 as "gifts-carousel-gifts", _2 as "gifts-carousel-gifts__dots", _3 as "gifts-carousel-gifts__dots__item", _4 as "gifts-carousel-gifts__dots__item__m-active", _5 as "gifts-carousel-gifts__media", _6 as "gifts-carousel-gifts__media__item", _7 as "gifts-carousel-gifts__title", _8 as "gifts-carousel-gifts__title__value" }
