// extracted by mini-css-extract-plugin
var _1 = "bpl__gifts-carousel__UAjU6";
var _2 = "bpl__gifts-carousel__content__KFk5l";
var _3 = "bpl__gifts-carousel__content__slider__eeDOF";
var _4 = "bpl__gifts-carousel__content__slider__item__bkyNP";
var _5 = "bpl__gifts-carousel__content__slider__item__gift__Mxaw5";
var _6 = "bpl__gifts-carousel__content__slider__item__position__D9r4I";
var _7 = "bpl__gifts-carousel__content__slider__m-transition__UWn_W";
var _8 = "bpl__gifts-carousel__control__RhKtW";
var _9 = "bpl__gifts-carousel__control__m-left__NMcWv";
var _a = "bpl__gifts-carousel__control__m-right__jOo3R";
var _b = "bpl__gifts-carousel__m-loading__q6C1B";
export { _1 as "gifts-carousel", _2 as "gifts-carousel__content", _3 as "gifts-carousel__content__slider", _4 as "gifts-carousel__content__slider__item", _5 as "gifts-carousel__content__slider__item__gift", _6 as "gifts-carousel__content__slider__item__position", _7 as "gifts-carousel__content__slider__m-transition", _8 as "gifts-carousel__control", _9 as "gifts-carousel__control__m-left", _a as "gifts-carousel__control__m-right", _b as "gifts-carousel__m-loading" }
