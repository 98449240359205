import * as React from 'react';
import { Outlet } from 'react-router';

import { ClassName } from 'src/lib';

export interface ILayoutMainPropTypes {
	header?: React.ReactNode;
	footer?: React.ReactNode;
}

const cn = ClassName.create('layout-main', require('./style.less'));

export function LayoutMain(props: ILayoutMainPropTypes): React.ReactNode {
	return (
		<div className={cn.get()}>
			{!!props.header && <header className={cn.get('header')}>{props.header}</header>}
			<main className={cn.get('content')}>
				<Outlet />
			</main>
			{!!props.footer && <footer className={cn.get('footer')}>{props.footer}</footer>}
		</div>
	);
}
