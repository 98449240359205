import * as React from 'react';
import { observer } from 'mobx-react';
import anime from 'animejs';

import _clamp from 'lodash/clamp';
import _times from 'lodash/times';
import _isNil from 'lodash/isNil';

import { type ILeaderboardGiftItem } from 'src/app';

import { ClassName } from 'src/lib';

export interface IGiftsCarouselGiftPropTypes {
	items: ILeaderboardGiftItem[];
}

const cn = ClassName.create('gifts-carousel-gifts', require('./style.less'));

export function GiftsCarouselGiftsComponent(
	props: IGiftsCarouselGiftPropTypes
): React.ReactElement<IGiftsCarouselGiftPropTypes> {
	const { items } = props;

	const [index, setIndex] = React.useState(0);
	const refMedia = React.useRef<HTMLDivElement>(null);
	const refTitle = React.useRef<HTMLDivElement>(null);

	const timeoutId = React.useRef<NodeJS.Timeout>();

	const moveToIndex = (next?: number) => {
		clearTimeout(timeoutId.current);

		if (items.length < 2) {
			return;
		}

		const getIndexInRange = (i: number) => {
			if (i < 0 || i > items.length - 1) {
				return 0;
			}

			return i;
		};

		next = getIndexInRange(next ?? index + 1);

		if ([refMedia.current, refTitle.current].some((ref) => _isNil(ref))) {
			return;
		}

		Promise.all([
			Promise.all([
				new Promise<void>((resolve) => {
					anime({
						targets: [refMedia.current],
						opacity: 0,
						translateX: ['0%', next < index ? '100%' : '-100%'],
						duration: 300,
						easing: 'linear',
						complete: () => {
							resolve();
						},
					});
				}),
				new Promise<void>((resolve) => {
					anime({
						targets: [refTitle.current],
						opacity: 0,
						translateY: '100%',
						duration: 300,
						easing: 'linear',
						complete: () => {
							resolve();
						},
					});
				}),
			]),
			new Promise<void>((resolve) => {
				const i = getIndexInRange(index);
				const src = items[i]?.image;

				if (!src) {
					return resolve();
				}

				const img = document.createElement('img');

				img.style.position = 'absolute';
				img.style.width = '1px';
				img.style.height = '1px';
				img.style.top = '-9999px';
				img.style.left = '-9999px';

				Promise.race([
					new Promise<void>((resolve) => {
						img.addEventListener('load', () => {
							resolve();
							document.body.removeChild(img);
						});
					}),
					new Promise<void>((resolve) => {
						setTimeout(resolve, 500);
					}),
				]).then(resolve);

				img.src = src as string;
				img.alt = '';

				document.body.appendChild(img);
			}),
		]).then(() => {
			setIndex(next);

			if ([refMedia.current, refTitle.current].some((ref) => _isNil(ref))) {
				return;
			}

			Promise.all([
				new Promise<void>((resolve) => {
					anime({
						targets: [refMedia.current],
						opacity: 1,
						translateX: [next < index ? '-100%' : '100%', '0%'],
						duration: 200,
						easing: 'easeOutCirc',
						complete: () => resolve(),
					});
				}),
				new Promise<void>((resolve) => {
					anime({
						targets: [refTitle.current],
						opacity: 1,
						translateY: '0%',
						duration: 200,
						easing: 'easeOutQuad',
						complete: () => resolve(),
					});
				}),
			]).then(() => {
				timeoutId.current = setTimeout(() => moveToIndex(), 3500);
			});
		});
	};

	React.useLayoutEffect(() => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(moveToIndex, 2000);

		return () => {
			clearTimeout(timeoutId.current);
		};
	}, [index]);

	const item = items[index];

	return (
		<div className={cn.get()}>
			<div className={cn.get('media')}>
				<div
					ref={refMedia}
					className={cn.get('media', 'item')}
					style={{ backgroundImage: `url("${item?.image || ''}")` }}
				/>
			</div>
			<div className={cn.get('dots')}>
				{items.length > 1 &&
					_times(items.length, (i) => (
						<button
							key={`item-${i}`}
							className={cn.get('dots', 'item', [i === index && 'active'])}
							onClick={() => {
								moveToIndex(i);
							}}
						/>
					))}
			</div>
			<div className={cn.get('title')}>
				<div ref={refTitle} className={cn.get('title', 'value')}>
					{item?.title}
				</div>
			</div>
		</div>
	);
}

export const GiftsCarouselGifts = observer(GiftsCarouselGiftsComponent);
