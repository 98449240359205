export default {
	'type.physical': 'Physical',
	'type.digital': 'Digital',
	'type.promocode': 'Promo code',
	'type.unknown': '—',
	'physical.address': 'Share your address to get your gift',
	'promocode.waiting': 'Waiting, check later',
	'promocode.copy': 'Click here to copy',
	'promocode.copied': 'Copied to clipboard',
};
