// extracted by mini-css-extract-plugin
var _1 = "bpl__heading__d4Z6z";
var _2 = "bpl__heading__m-f-accent__HZt2s";
var _3 = "bpl__heading__m-f-base__n7y6q";
var _4 = "bpl__heading__m-l-1__YIztI";
var _5 = "bpl__heading__m-l-2__JvCY_";
var _6 = "bpl__heading__m-l-3__UTZPX";
var _7 = "bpl__heading__m-l-4__lPnmR";
var _8 = "bpl__heading__m-l-5__yEUC1";
var _9 = "bpl__heading__m-l-6__WGXCu";
var _a = "bpl__heading__m-v-accent__IcHAd";
var _b = "bpl__heading__m-v-base__cggxG";
export { _1 as "heading", _2 as "heading__m-f-accent", _3 as "heading__m-f-base", _4 as "heading__m-l-1", _5 as "heading__m-l-2", _6 as "heading__m-l-3", _7 as "heading__m-l-4", _8 as "heading__m-l-5", _9 as "heading__m-l-6", _a as "heading__m-v-accent", _b as "heading__m-v-base" }
