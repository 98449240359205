import * as React from 'react';

import { ClassName } from 'src/lib';

import { Lexical, type TLexicalContent } from './lexical';

export interface ITextBlockPropTypes {
	content: TLexicalContent;
}

const cn = ClassName.create('text-block', require('./style.less'));

export function TextBlock(props: ITextBlockPropTypes): React.ReactElement<ITextBlockPropTypes> {
	return (
		<div className={cn.get()}>
			<Lexical content={props.content} />
		</div>
	);
}

export * from './lexical';
