import * as React from 'react';

import { ClassName } from 'src/lib';

export interface IBoxPropTypes extends React.PropsWithChildren {
	border?: 'top' | 'bottom';
	isPadding?: boolean;
	isBackground?: boolean;
}

const cn = ClassName.create('box', require('./style.less'));

export function Box(props: IBoxPropTypes): React.ReactElement<IBoxPropTypes> {
	return (
		<div
			className={cn.get([
				!!props.border && ['border', props.border].join('-'),
				props.isPadding && 'padding',
				props.isBackground && 'background',
			])}
		>
			{props.children}
		</div>
	);
}
