import * as React from 'react';
import { observer } from 'mobx-react';

import numeral from 'numeral';

import { Marquee } from 'src/components/marquee';

import { ClassName } from 'src/lib';

export interface ILeaderboardRowPropTypes {
	username: string;
	position: number;
	score: number;
	gift?: string;
	isSelected?: boolean;
	isLoading?: boolean;
}

const cn = ClassName.create('leaderboard-row', require('./style.less'));

export function LeaderboardRowComponent(props: ILeaderboardRowPropTypes): React.ReactElement<ILeaderboardRowPropTypes> {
	const isLoading = !!props.isLoading;

	const [isScrolling, setIsScrolling] = React.useState(!!props.isSelected);

	return (
		<div
			className={cn.get([isLoading && 'loading'])}
			style={{
				...(isLoading
					? ({ '--element-ld-animation-delay': `${(props.position % 25) * 20}ms` } as React.CSSProperties)
					: {}),
			}}
		>
			{!isLoading && (
				<div
					className={cn.get('data', [`position-${props.position}`, props.isSelected && 'selected'])}
					onTouchStart={() => {
						setIsScrolling(true);
					}}
					onTouchEnd={() => {
						setIsScrolling(!!props.isSelected);
					}}
					onMouseOver={() => {
						setIsScrolling(true);
					}}
					onMouseOut={() => {
						setIsScrolling(!!props.isSelected);
					}}
				>
					<div className={cn.get('data', 'position')}>{props.position}</div>
					<div className={cn.get('data', 'username')}>{props.username}</div>
					<div className={cn.get('data', 'gift')}>
						<Marquee duration={2000} delayIn={500} delayOut={1500} isScrolling={isScrolling}>
							{props.gift}
						</Marquee>
					</div>
					<div className={cn.get('data', 'score')}>{numeral(props.score).format('0,0')}</div>
				</div>
			)}
		</div>
	);
}

export const LeaderboardRow = observer(LeaderboardRowComponent);
