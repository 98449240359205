import * as React from 'react';
import { useLocation, matchPath } from 'react-router';

import { ClassName } from 'src/lib';

import { Tabs, ITabsPropTypes } from 'src/components/tabs';

import { generatePath } from 'src/utils';

export interface ISubnavigationPropTypes {
	items: (Omit<ITabsPropTypes['items'][0], 'url' | 'content' | 'isSelected'> & {
		path: string;
		parameters?: Record<string, string>;
	})[];
}

const cn = ClassName.create('route--profile-navigation');

export function Subnavigation(props: ISubnavigationPropTypes): React.ReactElement<ISubnavigationPropTypes> {
	const location = useLocation();

	return (
		<div className={cn.get()}>
			<Tabs
				items={props.items.map((item) => {
					const url = generatePath(item.path, item.parameters);

					return {
						...item,
						url,
						isSelected:
							location.pathname === url ||
							window.location.pathname === url ||
							!!matchPath(item.path, location.pathname),
					};
				})}
			/>
		</div>
	);
}
