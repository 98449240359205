import * as React from 'react';

import { ClassName } from 'src/lib';

export interface IActionTablePropTypes extends React.PropsWithChildren {}

const cn = ClassName.create('action-table', require('./style.less'));

export function ActionTable(props: IActionTablePropTypes): React.ReactElement<IActionTablePropTypes> {
	return <div className={cn.get()}>{props.children}</div>;
}

export * from './row';
