import * as React from 'react';

import { ClassName } from 'src/lib';

export interface IThrobberPropTypes {
	size?: 'default' | 'small';
}

const cn = ClassName.create('throbber', require('./style.less'));

export function Throbber(props: IThrobberPropTypes): React.ReactElement<typeof Throbber> {
	return <div className={cn.get([`size-${props.size || 'default'}`])} />;
}
