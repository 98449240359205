import * as React from 'react';
import { Helmet } from 'react-helmet';

import { getPageTitle, TPageTitlePart } from 'src/utils';

export interface IPageHeadPropTypes {
	title?: TPageTitlePart | TPageTitlePart[];
}

export function PageHead(props: IPageHeadPropTypes): React.ReactElement<IPageHeadPropTypes> {
	return (
		<Helmet>
			<title>{getPageTitle.call(null, Array.isArray(props.title) ? props.title : [props.title])}</title>
		</Helmet>
	);
}
