import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';

import _inRange from 'lodash/inRange';
import _isEqual from 'lodash/isEqual';

import { ClassName } from 'src/lib';

import type { LeaderboardsGiftsModel } from 'src/app';

import { GiftsCarouselGifts } from './gifts';

import './intl';

export interface IGiftsCarouselPropTypes {
	gifts: LeaderboardsGiftsModel;
	autoscroll?: boolean;
	autoscrollInterval?: number;
}

const cn = ClassName.create('gifts-carousel', require('./style.less'));

export function GiftsCarouselComponent(props: IGiftsCarouselPropTypes): React.ReactNode {
	const intl = useIntl();

	const [index, setIndex] = React.useState(0);
	const [hover, setHover] = React.useState(false);
	const [transition, setTransition] = React.useState(true);
	const [nodeDimensions, setNodeDimensionsState] = React.useState({
		width: 0,
		gap: 0,
	});
	const [itemWidth, setItemWidth] = React.useState(0);
	const [touchX, setTouchX] = React.useState({
		start: 0,
		end: 0,
	});

	const setNodeDimensions = (width: number) => {
		if ([width, itemWidth].some((value) => value <= 0)) {
			return;
		}

		const calculateGap = (itemsAmountToShow: number) => {
			return (width - itemWidth * itemsAmountToShow) / (itemsAmountToShow - 1);
		};

		let itemsAmountToShow = Math.max(Math.min(Math.floor(width / (itemWidth + nodeDimensions.gap / 2)), 8), 2);
		let gap = calculateGap(itemsAmountToShow);

		if (gap > itemWidth / 4) {
			itemsAmountToShow = Math.min(itemsAmountToShow + 1, 8);
			gap = calculateGap(itemsAmountToShow);
		}

		if (gap < 8) {
			itemsAmountToShow = Math.max(itemsAmountToShow - 1, 2);
			gap = calculateGap(itemsAmountToShow);
		}

		setNodeDimensionsState((dimensions) => ({ ...dimensions, width, gap }));
	};

	const autoscroll = !hover && (props.autoscroll === Boolean(props.autoscroll) ? props.autoscroll : true);
	const autoscrollTimeout = Number.isFinite(props.autoscrollInterval)
		? Math.max(props.autoscrollInterval as number, 1000)
		: 5000;
	const autoscrollTimeoutId = React.useRef<NodeJS.Timeout | null>(null);

	const transitionTimeoutId = React.useRef<NodeJS.Timeout | null>(null);

	const gifts = React.useMemo(
		() =>
			props.gifts.list.sort((a, b) => {
				const posA = Array.isArray(a.position) ? a.position[0] : a.position;
				const posB = Array.isArray(b.position) ? b.position[0] : b.position;

				return posA - posB;
			}),
		[props.gifts.list]
	);

	const nodeResizeObserver = new ResizeObserver((entries) => {
		const width = entries[0]?.borderBoxSize[0]?.inlineSize || 0;

		if (width !== nodeDimensions.width) {
			setNodeDimensions(width);
		}
	});

	const moveToIndex = React.useCallback(
		(index: number) => {
			setIndex(Math.min(Math.max(index, 0), gifts.length * 2 - 1));
		},
		[index, gifts.length]
	);

	React.useLayoutEffect(() => {
		function tick() {
			clearTimeout(autoscrollTimeoutId.current as NodeJS.Timeout);

			if (!autoscroll) {
				return;
			}

			moveToIndex(index + 1);

			autoscrollTimeoutId.current = setTimeout(tick, autoscrollTimeout);
		}

		autoscrollTimeoutId.current = setTimeout(tick, autoscrollTimeout);

		return () => clearTimeout(autoscrollTimeoutId.current as NodeJS.Timeout);
	}, [index, gifts.length, autoscroll, autoscrollTimeout]);

	React.useLayoutEffect(() => {
		return () => {
			nodeResizeObserver?.disconnect();
			nodeResizeObserver == null;

			clearTimeout(transitionTimeoutId.current as NodeJS.Timeout);
		};
	}, []);

	if (!gifts.length) {
		return null;
	}

	return (
		<div
			className={cn.get()}
			ref={(element) => {
				if (!element) {
					return;
				}

				nodeResizeObserver?.observe(element);
			}}
			onMouseOver={() => {
				setHover(true);
			}}
			onMouseOut={() => {
				setHover(false);
			}}
			onTouchStart={(event) => {
				setHover(true);
				setTouchX((coords) => ({ ...coords, start: event.touches[0]?.clientX ?? 0 }));
			}}
			onTouchMove={(event) => {
				setTouchX((coords) => ({ ...coords, end: event.touches[0]?.clientX ?? 0 }));
			}}
			onTouchEnd={(event) => {
				moveToIndex(index - Math.sign(touchX.end - touchX.start));
				setHover(false);
			}}
		>
			{['left', 'right'].map((direction) => (
				<button
					key={direction}
					className={cn.get('control', [direction])}
					onClick={() => {
						moveToIndex(index + (direction === 'right' ? 1 : -1));
					}}
				/>
			))}
			<div className={cn.get('content')}>
				<div
					className={cn.get('content', 'slider', [transition && 'transition'])}
					style={{
						gap: [nodeDimensions.gap, 'px'].join(''),
						transform: `translateX(-${index * (itemWidth + nodeDimensions.gap)}px)`,
					}}
					onTransitionEnd={() => {
						const length = gifts.length;

						if (index >= 0 && index < length) {
							return;
						}

						setTransition(false);
						setIndex(Math.max(index - length, 0));

						transitionTimeoutId.current = setTimeout(() => {
							setTransition(true);
						}, 200);
					}}
				>
					{[...gifts, ...gifts].map((gift, i) => {
						return (
							<div
								key={[gift.id, i].join('-')}
								ref={(element) => {
									if (!element || i !== 0 || !!itemWidth) {
										return;
									}

									setItemWidth(element.getBoundingClientRect().width);
								}}
								className={cn.get('content', 'slider', 'item', [i === index && 'active'])}
								onClick={() => moveToIndex(i)}
							>
								<div className={cn.get('content', 'slider', 'item', 'position')}>
									{new Set(gift.position).size === 1
										? intl.formatMessage(
												{ id: 'gifts-carousel.position-single' },
												{
													position: gift.position[0],
												}
											)
										: intl.formatMessage(
												{ id: 'gifts-carousel.position-range' },
												{
													positionStart: gift.position[0],
													positionEnd: gift.position[1],
												}
											)}
								</div>
								<div className={cn.get('content', 'slider', 'item', 'gift')}>
									<GiftsCarouselGifts items={gift.items} />
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export const GiftsCarousel = observer(GiftsCarouselComponent);
