import * as React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ClassName } from 'src/lib';

import { UserModel } from 'src/app';

import { HeaderProfile } from './profile';

export interface IHeaderPropTypes {
	user: UserModel;
}

const cn = ClassName.create('header', require('./style.less'));

export function HeaderComponent(props: IHeaderPropTypes): React.ReactElement<IHeaderPropTypes> {
	return (
		<div className={cn.get()}>
			<div className={cn.get('content')}>
				<Link className={cn.get('content', 'logo')} to="/" />
				<div className={cn.get('profile')}>
					<HeaderProfile user={props.user} />
				</div>
			</div>
		</div>
	);
}

export const Header = observer(HeaderComponent);
