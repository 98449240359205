export default {
	'type.physical': 'Физический',
	'type.digital': 'Цифровой',
	'type.promocode': 'Промо-код',
	'type.unknown': '—',
	'physical.address': 'Нажми, чтобы оставить свой адрес и получить приз',
	'promocode.waiting': 'Начисляется, проверь позднее',
	'promocode.copy': 'Нажми, чтобы скопировать',
	'promocode.copied': 'Скопировано в буфер обмена',
};
