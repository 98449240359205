import * as React from 'react';
import { observer } from 'mobx-react';

import { ClassName } from 'src/lib';

import type { LeaderboardModel } from 'src/app';

import { Tabs, BoxTitle } from 'src/components';

import { getDatesRange } from 'src/utils';

export interface ILeaderboardSelectorPropTypes {
	leaderboard: LeaderboardModel;
}

const cn = ClassName.create('leaderboard-selector', require('./style.less'));

export function LeaderboardSelectorComponent(
	props: ILeaderboardSelectorPropTypes
): React.ReactElement<ILeaderboardSelectorPropTypes> {
	const { leaderboard } = props;

	return (
		<div className={cn.get()}>
			<div className={cn.get('tabs')}>
				<Tabs
					items={leaderboard.leaderboards.map((item) => ({
						key: item.id,
						title: item.title,
						url: item.url,
						content: (
							<div className={cn.get('tabs', 'item')}>
								<div
									className={cn.get('tabs', 'item', 'logo')}
									style={{
										backgroundImage: !!item?.images.logo ? `url(${item?.images.logo})` : undefined,
									}}
								/>
								<div className={cn.get('tabs', 'item', 'data')}>
									<div className={cn.get('tabs', 'item', 'data', 'subtitle')}>
										{getDatesRange(item?.dates?.begin, item?.dates?.end)}
									</div>
									<div className={cn.get('tabs', 'item', 'data', 'title')}>{item.title}</div>
								</div>
							</div>
						),
						isSelected: leaderboard.currentLeaderboard?.resourceId === item.resourceId,
					}))}
				/>
			</div>
			<div className={cn.get('title')}>
				<BoxTitle
					title={leaderboard.currentLeaderboard?.title}
					subtitle={getDatesRange(
						leaderboard.currentLeaderboard?.dates?.begin,
						leaderboard.currentLeaderboard?.dates?.end
					)}
					description={leaderboard.currentLeaderboard?.description}
					image={leaderboard.currentLeaderboard?.images.background || ''}
				/>
			</div>
		</div>
	);
}

export const LeaderboardSelector = observer(LeaderboardSelectorComponent);
