import * as React from 'react';

import { ClassName } from 'src/lib';

import { Box } from '../component';

export interface IBoxTitlePropTypes {
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	description?: React.ReactNode;
	image?: string;
}

const cn = ClassName.create('box-title', require('./style.less'));

export function BoxTitle(props: IBoxTitlePropTypes): React.ReactElement<IBoxTitlePropTypes> {
	const gradientIdDesktop = React.useId();
	const maskIdDesktop = React.useId();

	return (
		<Box border="top" isBackground>
			<div className={cn.get()}>
				<div className={cn.get('data')}>
					{(['title', 'subtitle', 'description'] as (keyof IBoxTitlePropTypes)[])
						.map(
							(key) =>
								props[key] && (
									<div key={key} className={cn.get('data', key)}>
										{props[key]}
									</div>
								)
						)
						.filter(Boolean)}
				</div>
				<div className={cn.get('image')}>
					<svg width="100%" height="100%">
						<defs>
							<linearGradient id={gradientIdDesktop} gradientTransform="rotate(0)">
								<stop offset="0" stopColor="white" stopOpacity="0" />
								<stop offset="0.6" stopColor="white" stopOpacity="1" />
							</linearGradient>
							<mask id={maskIdDesktop}>
								<rect x="0" y="0" width="100%" height="100%" fill={`url(#${gradientIdDesktop})`} />
							</mask>
						</defs>
						<image
							className={cn.get('image', 'instance', ['desktop'])}
							href={props.image}
							x="0"
							y="0"
							width="100%"
							height="100%"
							preserveAspectRatio="xMinYMin slice"
							mask={`url(#${maskIdDesktop})`}
						/>
					</svg>
				</div>
			</div>
		</Box>
	);
}
