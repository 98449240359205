// extracted by mini-css-extract-plugin
var _1 = "bpl__box-title__XO_Me";
var _2 = "bpl__box-title__data__rNJ_b";
var _3 = "bpl__box-title__data__description__ZYcde";
var _4 = "bpl__box-title__data__subtitle__MDxUf";
var _5 = "bpl__box-title__data__title__z2qYt";
var _6 = "bpl__box-title__image__hHsJq";
var _7 = "bpl__box-title__image__instance__udRYS";
var _8 = "bpl__box-title__image__instance__m-desktop__b8mRN";
var _9 = "bpl__box-title__image__instance__m-mobile__XQmWf";
export { _1 as "box-title", _2 as "box-title__data", _3 as "box-title__data__description", _4 as "box-title__data__subtitle", _5 as "box-title__data__title", _6 as "box-title__image", _7 as "box-title__image__instance", _8 as "box-title__image__instance__m-desktop", _9 as "box-title__image__instance__m-mobile" }
