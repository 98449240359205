import * as React from 'react';

import _get from 'lodash/get';

import { ClassName } from 'src/lib';

const cn = ClassName.create('heading', require('./style.less'));

export interface IHeadingPropTypes extends React.PropsWithChildren {
	className?: React.ReactHTMLElement<HTMLHeadingElement>['props']['className'];
	level?: 1 | 2 | 3 | 4 | 5 | 6;
	variant?: 'base' | 'accent';
	font?: 'base' | 'accent';
}

export function Heading(props: IHeadingPropTypes): React.ReactNode {
	const level = _get(props, 'level', 2);
	const vairant = _get(props, 'color', 'base');
	const font = _get(props, 'font', 'base');

	return React.createElement(
		['h', level].join(''),
		{
			className: ClassName.join(cn.get([`l-${level}`, `v-${vairant}`, `f-${font}`]), props.className),
		},
		props.children
	);
}
