import * as React from 'react';
import { Link } from 'react-router-dom';

import { ClassName } from 'src/lib';

export interface ITabsPropTypes {
	items: {
		key: string;
		title: string;
		url?: string;
		content?: React.ReactNode;
		isSelected?: boolean;
	}[];
}

const cn = ClassName.create('tabs', require('./style.less'));

export function Tabs(props: ITabsPropTypes): React.ReactElement<ITabsPropTypes> {
	return (
		<div className={cn.get()}>
			{props.items.map((item) => {
				const Element = item.url ? Link : 'div';

				return React.createElement(
					Element,
					{
						key: item.key,
						className: cn.get('item', [!!item.content && 'custom', item.isSelected && 'selected']),
						to: item.url as string,
					},
					item.content ?? <span className={cn.get('item', 'title')}>{item.title}</span>
				);
			})}
		</div>
	);
}
