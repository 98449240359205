import * as React from 'react';

import { ClassName } from 'src/lib';

const cn = ClassName.create('page-body', require('./style.less'));

export interface IPageBodyPropTypes extends React.PropsWithChildren {
	className?: React.ReactElement<HTMLDivElement>['props']['className'];
}

export function PageBody(props: IPageBodyPropTypes): React.ReactNode {
	return <div className={ClassName.join(cn.get(), props.className)}>{props.children}</div>;
}
