// extracted by mini-css-extract-plugin
var _1 = "bpl__leaderboard-row___nxwv";
var _2 = "bpl__leaderboard-row__data__mFkRY";
var _3 = "bpl__leaderboard-row__data__gift__kjkHc";
var _4 = "bpl__leaderboard-row__data__m-selected__EXUQN";
var _5 = "bpl__leaderboard-row__data__position__V3dou";
var _6 = "bpl__leaderboard-row__data__score__iHWLT";
var _7 = "bpl__leaderboard-row__data__username__vCTnK";
var _8 = "bpl__leaderboard-row__m-loading__rdZJK";
var _9 = "bpl__loading__Ip3LM";
export { _1 as "leaderboard-row", _2 as "leaderboard-row__data", _3 as "leaderboard-row__data__gift", _4 as "leaderboard-row__data__m-selected", _5 as "leaderboard-row__data__position", _6 as "leaderboard-row__data__score", _7 as "leaderboard-row__data__username", _8 as "leaderboard-row__m-loading", _9 as "loading" }
