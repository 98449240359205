import * as React from 'react';
import { observer } from 'mobx-react';

import _times from 'lodash/times';
import _inRange from 'lodash/inRange';

import { ClassName } from 'src/lib';

export interface ILeaderboardPaginationPropTypes {
	page: number;
	pageSize: number;
	totalSize: number;
	setPage: (page: number) => void;
}

const cn = ClassName.create('leaderboard-pagination', require('./style.less'));

export function LeaderboardPaginationComponent(
	props: ILeaderboardPaginationPropTypes
): React.ReactElement<ILeaderboardPaginationPropTypes> {
	const size = Math.ceil(props.totalSize / props.pageSize);

	return (
		<div className={cn.get()}>
			{_times(size, (index) => {
				const page = index + 1;
				const current = props.page;

				const isFirst = page === 1;
				const isLast = page === size;

				const isInLimit = _inRange(page, Math.max(current - 3, 0), Math.min(current + 4, size));

				const isInFrontOfLimit = !isFirst && page === current - 4;
				const isInBackOfLimit = !isLast && page === current + 5;

				if (isInFrontOfLimit || isInBackOfLimit) {
					return (
						<span
							key={['ellipsis', isInFrontOfLimit && 'front', isInBackOfLimit && 'back']
								.filter(Boolean)
								.join('=')}
							className={cn.get('ellipsis')}
						>
							…
						</span>
					);
				}

				if (!(isFirst || isLast || isInLimit)) {
					return null;
				}

				const isCurrent = page === current;

				return (
					<button
						key={`pagination--button-${page}`}
						className={cn.get('button', [isCurrent && 'current'])}
						onClick={() => props.setPage(page)}
					>
						{page}
					</button>
				);
			})}
		</div>
	);
}

export const LeaderboardPagination = observer(LeaderboardPaginationComponent);
