import * as React from 'react';

import { ClassName } from 'src/lib';

export interface IStatusPropTypes {
	value?: 'idle' | 'waiting' | 'success' | 'error';
}

const cn = ClassName.create('status', require('./style.less'));

export function Status(props: IStatusPropTypes): React.ReactElement<IStatusPropTypes> {
	const value = props.value ?? 'idle';

	return <div className={cn.get([`status-${value}`])} />;
}
