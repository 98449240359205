import * as React from 'react';

import { ClassName } from 'src/lib';

import { Heading } from '../typography';

const cn = ClassName.create('rules-list', require('./style.less'));

export interface IRulesListPropTypes {
	rules: string[];
}

export function RulesList(props: IRulesListPropTypes): React.ReactNode {
	return (
		<div className={cn.get()}>
			<Heading level={5}>
				Принимая участие в Конкурсе, Зритель подтверждает, осознает и согласен, что:
				<em className={cn.get('disclaimer-mark')} />
			</Heading>
			<dl className={cn.get('list')}>
				{props.rules.map((rule, index) => (
					<React.Fragment key={['rule', index].join('-')}>
						<dt className={cn.get('list', 'item')}>
							<span className={cn.get('list', 'item', 'number')}>
								{[0, index + 1].join('').slice(-2)}
							</span>
							<span className={cn.get('list', 'item', 'text')}>{rule}</span>
						</dt>
					</React.Fragment>
				))}
			</dl>
			<div className={cn.get('disclaimer')}>Данные правила Конкурса не являются публичной офертой</div>
		</div>
	);
}
