import * as React from 'react';
import { Link } from 'react-router-dom';

import { ClassName } from 'src/lib';

export interface IButtonPropTypes extends React.PropsWithChildren {
	Element: 'a' | 'button' | React.FunctionComponent<React.ComponentProps<typeof Link>>;
	to?: string;
	target?: '_self' | '_blank';
	arrowOnHover?: undefined | false | 'right' | 'bottom';
	type?: 'button' | 'submit' | 'reset';
	onClick?: (event: React.MouseEvent) => void;
}

const cn = ClassName.create('button', require('./style.less'));

export function Button(props: IButtonPropTypes): React.ReactElement<Omit<IButtonPropTypes, 'Element'>> {
	return React.createElement(
		props.Element as 'button',
		{
			className: cn.get([!!props.arrowOnHover && `arrow-on-hover-${props.arrowOnHover}`]),
			...(props.Element === 'a' ? { href: props.to, target: props.target } : {}),
			...(props.Element === Link ? { to: props.to, target: props.target } : {}),
			...(props.Element === 'button' ? { type: props.type ?? 'button' } : {}),
			onClick: props.onClick,
		},
		props.children
	);
}
