import * as React from 'react';

import { ClassName } from 'src/lib';

import { BiLogoTwitch, BiLogoYoutube, BiLogoTelegram, BiQuestionMark } from 'react-icons/bi';

export interface IConnectAccountButtonPropTypes {
	network: string | 'twitch' | 'google' | 'telegram';
	href: string;
	isConnected?: boolean;
	isDisabled?: boolean;
}

const cn = ClassName.create('connect-account-button', require('./style.less'));

function _getLabel(network: IConnectAccountButtonPropTypes['network']): React.ReactNode {
	return (
		{
			twitch: 'Twitch',
			google: 'YouTube',
			telegram: 'Telegram',
		}[network] || 'Unknown'
	);
}

function _getIcon(network: IConnectAccountButtonPropTypes['network']): React.ReactNode {
	return (
		{
			twitch: <BiLogoTwitch style={{ marginTop: '.75rem' }} />,
			google: <BiLogoYoutube style={{ marginTop: '.75rem' }} />,
			telegram: <BiLogoTelegram />,
		}[network] || <BiQuestionMark />
	);
}

export function ConnectAccountButton(props: IConnectAccountButtonPropTypes): React.ReactNode {
	return (
		<a
			className={cn.get([props.network, props.isConnected && 'connected'])}
			{...(!props.isConnected ? { href: props.href } : {})}
		>
			<span className={cn.get('icon')}>{_getIcon(props.network)}</span>
			<span className={cn.get('label')}>{_getLabel(props.network)}</span>
			<span className={cn.get('status')}>{props.isConnected ? 'Успешно привязан' : 'Привязать'}</span>
		</a>
	);
}
