// extracted by mini-css-extract-plugin
var _1 = "bpl__video-player__RrkRP";
var _2 = "bpl__video-player__m-playing__qSgkz";
var _3 = "bpl__video-player__overlay__fXrfE";
var _4 = "bpl__video-player__overlay__background__teCLf";
var _5 = "bpl__video-player__overlay__control___TZ_c";
var _6 = "bpl__video-player__overlay__control__icon__VfVs7";
var _7 = "bpl__video-player__overlay__control__icon__m-pause__wqIjB";
var _8 = "bpl__video-player__overlay__control__icon__m-play__SnBCi";
var _9 = "bpl__video-player__player__oeVNw";
export { _1 as "video-player", _2 as "video-player__m-playing", _3 as "video-player__overlay", _4 as "video-player__overlay__background", _5 as "video-player__overlay__control", _6 as "video-player__overlay__control__icon", _7 as "video-player__overlay__control__icon__m-pause", _8 as "video-player__overlay__control__icon__m-play", _9 as "video-player__player" }
