import React, { CSSProperties } from 'react';

export type TLexicalAbstractNode<Type extends string> = {
	format?: '' | 'start' | 'center' | 'right' | 'justify' | number;
	type: Type;
	version: number;
};

export type TLexicalAbstractElementNode<Type extends string> = {
	direction: 'ltr' | 'rtl' | null;
	indent: number;
} & TLexicalAbstractNode<Type>;

export type TLexicalAbstractTextNode<Type extends string> = {
	detail: number; // what is this
	format: '' | number;
	mode: 'normal'; // what is this
	style: string;
	text: string;
} & TLexicalAbstractNode<Type>;

export type TLexicalBlockNode<BlockData extends Record<string, unknown>, BlockType extends string> = {
	fields: {
		id: string;
		blockName: string;
		blockType: BlockType;
	} & BlockData;
} & TLexicalAbstractElementNode<'block'>;

type TLexicalBlockNodeUnknown = {
	fields: {
		id: string;
		blockName: string;
		blockType: string;
		[key: string]: unknown;
	};
} & TLexicalAbstractNode<'block'>;

export type TLexicalRoot = {
	children: TLexicalNode[];
} & TLexicalAbstractElementNode<'root'>;

export type TLexicalMark = {
	text: string;
	bold?: boolean;
	italic?: boolean;
	underline?: boolean;
	strikethrough?: boolean;
	code?: boolean;
	subscript?: boolean;
	superscript?: boolean;
	highlight?: boolean;
};

export type TLexicalTextNode = TLexicalAbstractTextNode<'text'>;
export type TLexicalLinebreak = TLexicalAbstractNode<'linebreak'>;
export type TLexicalTab = TLexicalAbstractTextNode<'tab'>;

export type TLexicalLinkNode = {
	children: TLexicalTextNode[];
	fields:
		| {
				linkType: 'custom';
				newTab: boolean;
				url: string;
		  }
		| {
				doc: {
					relationTo: string;
					value: unknown;
				};
				linkType: 'internal';
				newTab: boolean;
				url: string;
		  };
} & TLexicalAbstractElementNode<'link'>;

export type TLexicalLinkNodeAuto = {
	children: TLexicalTextNode[];
	fields: {
		linkType: 'custom';
		newTab?: boolean;
		url: string;
	};
} & TLexicalAbstractElementNode<'autolink'>;

export type TLexicalHeadingNode = {
	tag: string;
	children: TLexicalTextNode[];
} & TLexicalAbstractElementNode<'heading'>;

export type TLexicalParagraphNode = {
	children: (TLexicalTextNode | TLexicalLinebreak | TLexicalTab | TLexicalLinkNode | TLexicalLinkNodeAuto)[];
} & TLexicalAbstractElementNode<'paragraph'>;

export type TLexicalListItemNode = {
	children: (TLexicalTextNode | TLexicalListNode)[];
	value: number;
} & TLexicalAbstractElementNode<'listitem'>;

export type TLexicalListNode = {
	tag: string;
	listType: 'number' | 'bullet' | 'check';
	start: number;
	children: TLexicalListItemNode[];
} & TLexicalAbstractElementNode<'list'>;

export type TLexicalQuoteNode = {
	children: TLexicalTextNode[];
} & TLexicalAbstractElementNode<'quote'>;

export type TLexicalUploadNode<
	MediaType = {
		id: string;
		alt: string;
		updatedAt: string;
		createdAt: string;
		url?: string;
		filename?: string;
		mimeType?: string;
		filesize?: number;
		width?: number;
		height?: number;
	},
> = {
	fields: null;
	relationTo: 'media';
	value: MediaType;
} & TLexicalAbstractElementNode<'upload'>;

export type TLexicalNode =
	| TLexicalHeadingNode
	| TLexicalParagraphNode
	| TLexicalUploadNode
	| TLexicalTextNode
	| TLexicalListNode
	| TLexicalListItemNode
	| TLexicalQuoteNode
	| TLexicalLinebreak
	| TLexicalTab
	| TLexicalLinkNode
	| TLexicalBlockNodeUnknown
	| TLexicalLinkNodeAuto;

export type TLexicalElementRenderers = {
	heading: (props: { children: React.ReactNode } & Omit<TLexicalHeadingNode, 'children'>) => React.ReactNode;
	list: (props: { children: React.ReactNode } & Omit<TLexicalListNode, 'children'>) => React.ReactNode;
	listItem: (props: { children: React.ReactNode } & Omit<TLexicalListItemNode, 'children'>) => React.ReactNode;
	paragraph: (props: { children: React.ReactNode } & Omit<TLexicalParagraphNode, 'children'>) => React.ReactNode;
	quote: (props: { children: React.ReactNode } & Omit<TLexicalQuoteNode, 'children'>) => React.ReactNode;
	link: (props: { children: React.ReactNode } & Omit<TLexicalLinkNode, 'children'>) => React.ReactNode;
	autolink: (props: { children: React.ReactNode } & Omit<TLexicalLinkNodeAuto, 'children'>) => React.ReactNode;
	linebreak: () => React.ReactNode;
	tab: () => React.ReactNode;
	upload: (props: TLexicalUploadNode) => React.ReactNode;
};

export type TLexicalRenderMark = (mark: TLexicalMark) => React.ReactNode;

export type TLexicalContent = {
	root: TLexicalRoot;
};

export interface ITLexicalPropTypes<Blocks extends { [key: string]: any }> {
	content: TLexicalContent;
	elementRenderers?: TLexicalElementRenderers;
	renderMark?: TLexicalRenderMark;
	blockRenderers?: {
		[BlockName in Extract<keyof Blocks, string>]?: (
			props: TLexicalBlockNode<Blocks[BlockName], BlockName>
		) => React.ReactNode;
	};
}

// This copy-and-pasted from somewhere in lexical here: https://github.com/facebook/lexical/blob/c2ceee223f46543d12c574e62155e619f9a18a5d/packages/lexical/src/LexicalConstants.ts
const IS_BOLD = 1;
const IS_ITALIC = 1 << 1;
const IS_STRIKETHROUGH = 1 << 2;
const IS_UNDERLINE = 1 << 3;
const IS_CODE = 1 << 4;
const IS_SUBSCRIPT = 1 << 5;
const IS_SUPERSCRIPT = 1 << 6;
const IS_HIGHLIGHT = 1 << 7;

function getElementStyle<Type extends string>({ indent, format }: TLexicalAbstractElementNode<Type>): CSSProperties {
	const style: CSSProperties = {};

	if (indent > 0) {
		style.marginLeft = `${indent * 20}px`;
	}

	if (format === 'right' || format === 'center' || format === 'justify') {
		style.textAlign = format;
	}

	return style;
}

export const defaultElementRenderers: TLexicalElementRenderers = {
	heading: (element) => {
		return React.createElement(
			element.tag,
			{
				style: getElementStyle<'heading'>(element),
			},
			element.children
		);
	},
	list: (element) => {
		return React.createElement(
			element.tag,
			{
				style: getElementStyle<'list'>(element),
			},
			element.children
		);
	},
	listItem: (element) => {
		return <li style={getElementStyle<'listitem'>(element)}>{element.children}</li>;
	},
	paragraph: (element) => {
		return <p style={getElementStyle<'paragraph'>(element)}>{element.children}</p>;
	},
	link: (element) => (
		<a
			href={element.fields.url}
			target={element.fields.newTab ? '_blank' : '_self'}
			style={getElementStyle<'link'>(element)}
		>
			{element.children}
		</a>
	),
	autolink: (element) => (
		<a
			href={element.fields.url}
			target={element.fields.newTab ? '_blank' : '_self'}
			style={getElementStyle<'autolink'>(element)}
		>
			{element.children}
		</a>
	),
	quote: (element) => <blockquote style={getElementStyle<'quote'>(element)}>{element.children}</blockquote>,
	linebreak: () => <br />,
	tab: () => <br />,
	upload: (element) => {
		if (element.value.mimeType?.includes('image')) {
			return <img src={element.value.url} alt={element.value.alt} />;
		}

		return null;
	},
};

export const defaultRenderMark: TLexicalRenderMark = (mark) => {
	const style: CSSProperties = {};

	if (mark.bold) {
		style.fontWeight = 'bold';
	}

	if (mark.italic) {
		style.fontStyle = 'italic';
	}

	if (mark.underline) {
		style.textDecoration = 'underline';
	}

	if (mark.strikethrough) {
		style.textDecoration = 'line-through';
	}

	if (mark.code) {
		return <code>{mark.text}</code>;
	}

	if (mark.highlight) {
		return <mark style={style}>{mark.text}</mark>;
	}

	if (mark.subscript) {
		return <sub style={style}>{mark.text}</sub>;
	}

	if (mark.superscript) {
		return <sup style={style}>{mark.text}</sup>;
	}

	if (Object.keys(style).length === 0) {
		return <>{mark.text}</>;
	}

	return <span style={style}>{mark.text}</span>;
};

export function Lexical<Blocks extends { [key: string]: any }>({
	content,
	elementRenderers = defaultElementRenderers,
	renderMark = defaultRenderMark,
	blockRenderers = {},
}: ITLexicalPropTypes<Blocks>) {
	const renderElement = React.useCallback(
		(node: TLexicalNode, children?: React.ReactNode) => {
			if (!elementRenderers) {
				throw new Error("'elementRenderers' prop not provided.");
			}

			if (node.type === 'link' && node.fields) {
				return elementRenderers.link({
					...node,
					children,
				});
			}

			if (node.type === 'autolink' && node.fields) {
				return elementRenderers.autolink({
					...node,
					children,
				});
			}

			if (node.type === 'heading') {
				return elementRenderers.heading({
					...node,
					children,
				});
			}

			if (node.type === 'paragraph') {
				return elementRenderers.paragraph({
					...node,
					children,
				});
			}

			if (node.type === 'list') {
				return elementRenderers.list({
					...node,
					children,
				});
			}

			if (node.type === 'listitem') {
				return elementRenderers.listItem({
					...node,
					children,
				});
			}

			if (node.type === 'quote') {
				return elementRenderers.quote({
					...node,
					children,
				});
			}

			if (node.type === 'linebreak') {
				return elementRenderers.linebreak();
			}

			if (node.type === 'tab') {
				return elementRenderers.tab();
			}

			if (node.type === 'upload') {
				return elementRenderers.upload(node);
			}

			throw new Error(`Missing element renderer for node type '${node.type}'`);
		},
		[elementRenderers]
	);

	const renderText = React.useCallback(
		(node: TLexicalTextNode): React.ReactNode | null => {
			if (!renderMark) {
				throw new Error("'renderMark' prop not provided.");
			}

			if (!node.format) {
				return renderMark({
					text: node.text,
				});
			}

			return renderMark({
				text: node.text,
				bold: (node.format & IS_BOLD) > 0,
				italic: (node.format & IS_ITALIC) > 0,
				underline: (node.format & IS_UNDERLINE) > 0,
				strikethrough: (node.format & IS_STRIKETHROUGH) > 0,
				code: (node.format & IS_CODE) > 0,
				subscript: (node.format & IS_SUBSCRIPT) > 0,
				superscript: (node.format & IS_SUPERSCRIPT) > 0,
				highlight: (node.format & IS_HIGHLIGHT) > 0,
			});
		},
		[renderMark]
	);

	const serialize = React.useCallback(
		(children: TLexicalNode[]): React.ReactNode[] | null =>
			children.map((node, index) => {
				if (node.type === 'text') {
					return <React.Fragment key={index}>{renderText(node)}</React.Fragment>;
				}

				if (node.type === 'block') {
					const renderer = blockRenderers[node.fields.blockType] as (props: unknown) => React.ReactNode;

					if (typeof renderer !== 'function') {
						throw new Error(`Missing block renderer for block type '${node.fields.blockType}'`);
					}

					return <React.Fragment key={index}>{renderer(node)}</React.Fragment>;
				}

				if (node.type === 'linebreak' || node.type === 'tab' || node.type === 'upload') {
					return <React.Fragment key={index}>{renderElement(node)}</React.Fragment>;
				}

				return <React.Fragment key={index}>{renderElement(node, serialize(node.children))}</React.Fragment>;
			}),
		[renderElement, renderText, blockRenderers]
	);

	return <>{serialize(content.root.children)}</>;
}
